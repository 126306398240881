// Opacity

.opacity-01 {
  opacity: .01 !important;
}

.opacity-02 {
  opacity: .02 !important;
}

.opacity-03 {
  opacity: .03 !important;
}

.opacity-04 {
  opacity: .04 !important;
}

.opacity-05 {
  opacity: .05 !important;
}

.opacity-06 {
  opacity: .06 !important;
}

.opacity-07 {
  opacity: .07 !important;
}

.opacity-08 {
  opacity: .08 !important;
}

.opacity-09 {
  opacity: .09 !important;
}

.opacity-1 {
  opacity: .1 !important;
}

.opacity-15 {
  opacity: .15 !important;
}

.opacity-2 {
  opacity: .2 !important;
}

.opacity-3 {
  opacity: .3 !important;
}

.opacity-4 {
  opacity: .4 !important;
}

.opacity-5 {
  opacity: .5 !important;
}

.opacity-6 {
  opacity: .6 !important;
}

.opacity-7 {
  opacity: .7 !important;
}

.opacity-8 {
  opacity: .8 !important;
}

.opacity-9 {
  opacity: .9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}