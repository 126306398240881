@import "assets/base";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import '~ngx-toastr/toastr.css';
@import '@fortawesome/fontawesome-free/css/all.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
 ::ng-deep .atlas-field .mat-form-field-infix {
    padding: 0 !important; /* Adjust this value as needed */
}
.atlas-container {
    height: 100%;
    border-radius: 6px;
    border: 1px solid #E2E8F0;
    background: #FFF;
    padding: 2%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
}
.atlas-form-label{
    color: var(--Black, #000);
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
#toast-container>div {
    opacity: 1;
}

.toast-success {
    background-color: #51A351 !important;
}

.toast-error {
    background-color: #BD362F !important;
}

.toast-info {
    background-color: #2F96B4 !important;
}

.toast-warning {
    background-color: #F89406 !important;
}




::ng-deep .mat-form-field {
    width: -webkit-fill-available;
}


.Pop-Up-Box {
    z-index: 3 !important;
}



.ql-toolbar.ql-snow {
    position: sticky;
    background-color: white !important;
    top: 4.7em;

    z-index: 1 !important;
}

.linkClass {
    cursor: pointer;

    span {
        display: inline-block;
        background-color: rgb(128, 125, 125);
        color: white;
    }
}
.atlas-bold-label{
    font-family: 'Roboto';
    color: black;

    font-style: 'Medium';
    font-size: 14px;
    font-weight: 600;
}
.atlas-light-label{
    font-family: 'Roboto';
    color: #666666;
    font-style: 'Medium';
    font-size: 12px;
    font-weight: 400;
}
.atlas-light-label-14{
    font-family: 'Roboto';
    color: #666666;
    font-style: 'Medium';
    font-size: 14px;
    font-weight: 400;
}
.btn-atlas{
    cursor: pointer !important;
    background-color: #2D577C !important;
    border-color: white !important;
    color: white !important;
    font-size: 14px !important;
    font-family: "Roboto";
    font-weight: 400 !important;
    background-image: none !important;
    border-radius: 4px !important;
}
.btn-atlas:focus{
    box-shadow: 0 0 0 .2rem white !important;
}
.btn-full-width{
    width: 100%;
    display: flex;
    height: 48px;
    padding: 9px 17px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFF;
    font-family: 'Roboto';
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 24px; /* 150% */
}
.btn-atlas-cancel{
    background-color: white !important;
    cursor: pointer !important;
    border-color: #CCCCCC !important;
    font-size: 14px !important;
    color: black !important;
    border: 1px solid #CCCCCC !important;
    font-family: "Roboto";
    font-weight: 400 !important;
    background-image: none !important;
}
.btn-atlas-cancel:focus{
    box-shadow: 0 0 0 .2rem white !important;
}

.badge-atlas-warning {
	display: inline-flex;
	padding: 4px 16px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	font-family: 'Roboto';
	max-width: 100px;
	color: white;
	border-radius: 20px;
	background: var(--Primary, #F9B53A);
}
.badge-atlas-success {
	display: inline-flex;
	padding: 4px 16px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	font-family: 'Roboto';
	max-width: 100px;
	color: white;
	border-radius: 20px;
	background: var(--Primary, #72C45F);
}
.badge-atlas-danger {
	display: inline-flex;
	padding: 4px 16px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	font-family: 'Roboto';
	max-width: 100px;
	color: white;
	border-radius: 20px;
	background: var(--Primary, #FF3131);
}
.edit-button{
    display: inline-flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    background: var(--Blue, #2D577C);
    color: var(--White, #FFF);
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
}
.label-text{
    color: var(--Gray, #999);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    display: block !important;
    font-weight: 400;
    line-height: normal;
}
.value-text{
    color: var(--Black, #495057);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Roboto";
    font-size: 15px;
    font-style: normal;
    margin-top: 10px !important;
    font-weight: 500;
    line-height: normal;
}
.link-text{
    color: var(--Blue, #2D577C);
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
    cursor: pointer;
}

.white-back{
    background: white;
}
.spacer{
    flex: 1 1 auto;
}